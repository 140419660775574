var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3"},[_c('div',{staticClass:"w-100"},[_c('h1',{staticClass:"h3 text-center"},[_vm._v(" Ordenes encontradas "),_c('strong',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.itemsNumber)+" ")])]),_c('p',[_vm._v(" Por defecto el sistema consulta las ordenes en curso, es decir una orden que esta en un estado de entrega diferente al entregado o cancelado. ")])]),_c('div',{staticClass:"my-3"},[_c('orders-filters',{attrs:{"enabled-filters":_vm.$data.$enabledFilters},on:{"enter":_vm.reloadItems},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('vs-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.query),expression:"query"}],attrs:{"relief":""},on:{"click":_vm.reloadItems}},[_vm._v(" Consultar 🔎 ")])],1)],1),_c('div',{staticClass:"d-flex justify-content-between flex-column flex-lg-row my-2"},[_c('div',{staticClass:"d-flex justify-content-around"},[(
                    _vm.$ability.hasScope('order:Consultancy') &&
                    _vm.selected.length === 1
                )?_c('tooltip-button',{attrs:{"icon":"🗜","message":"Dividir y asignar proveedores"},on:{"click":_vm.goToRefactor}}):_vm._e(),(
                    _vm.$ability.hasScope('order:Complete') &&
                    _vm.selected.length === 1
                )?_c('tooltip-button',{attrs:{"icon":"🏁","message":"Completar orden"},on:{"click":_vm.completeOrderComponent}}):_vm._e(),_c('tooltip-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],attrs:{"icon":"🧹","message":"Limpiar selección"},on:{"click":_vm.clearSelected}})],1),_c('div',{staticClass:"d-flex align-items-end justify-content-around"},[_c('vs-pagination',{attrs:{"length":_vm.pagesNumber},on:{"input":function($event){return _vm.listItems()}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.$ability.hasScope('order:Create'))?_c('tooltip-redirect',{attrs:{"to":"/app/orders/new-in-merchant","message":"Registrar venta"}}):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"w-100 mt-2"},[_c('b-table',{ref:"selectableTable",staticClass:"col-12",attrs:{"fields":_vm.$data.$fields,"items":_vm.itemsCurrent,"dark":_vm.activeDark,"per-page":_vm.itemsCurrent.length,"select-mode":"multi","responsive":"","outlined":"","no-border-collapse":"","hover":"","selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{staticClass:"text-center font-weight-normal btn btn-primary p-1",attrs:{"to":("/app/orders/detail/" + (data.item.id))}},[_c('span',[_vm._v(" "+_vm._s(data.item.id))])])],1)]}},{key:"cell(deliveryStatusMessage)",fn:function(data){return [_c('small',{staticClass:"text-center font-weight-normal",class:_vm.$data.$deliveryStatusesMessageClass[
                            data.item.deliveryStatus
                        ]},[_vm._v(" "+_vm._s(data.item.deliveryStatusMessage)+" ")])]}},{key:"cell(deliveryOptionMessage)",fn:function(data){return [_c('small',{staticClass:"text-center",class:data.item.deliveryOption === 'home'
                            ? 'text-info'
                            : ''},[_vm._v(" "+_vm._s(data.item.deliveryOptionMessage)+" ")])]}},{key:"cell(paymentStatusMessage)",fn:function(data){return [_c('small',{staticClass:"text-center",class:_vm.$data.$paymentStatusesMessageClass[
                            data.item.paymentStatus
                        ]},[_vm._v(" "+_vm._s(data.item.paymentStatusMessage)+" ")])]}},{key:"cell(paymentMethodMessage)",fn:function(data){return [_c('small',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.item.paymentMethodMessage)+" ")])]}},{key:"cell(customer)",fn:function(data){return [(data.item.customer)?_c('small',{staticClass:"text-center"},[_vm._v(" "+_vm._s(((data.item.customer.firstName) + " " + (data.item.customer.lastName) + " / " + (data.item.customer.phone)))+" ")]):_c('small',[_vm._v(" Sin cliente asociado ")])]}},{key:"cell(total)",fn:function(data){return [_c('small',{staticClass:"text-center font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("money")(data.item.total))+" ")])]}},{key:"cell(deliveryAt)",fn:function(data){return [_c('small',{staticClass:"text-center font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("dateSimple")(data.item.deliveryAt))+" ")])]}},{key:"cell(createdAt)",fn:function(data){return [_c('small',{staticClass:"text-center font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("date")(data.item.createdAt))+" ")])]}},{key:"cell(updatedAt)",fn:function(data){return [_c('small',{staticClass:"text-center font-weight-normal"},[_vm._v(" "+_vm._s(_vm._f("date")(data.item.updatedAt))+" ")])]}}])}),(!_vm.itemsCurrent.length)?_c('div',{staticClass:"col-11 mx-auto mt-4"},[_c('strong',[_vm._v(" Nuestro sistema NO encuentra resultados. Intenta modificar los filtros de búsqueda. ")])]):_vm._e(),(_vm.itemsCurrent.length && _vm.$ability.hasScope('order:ListAll'))?_c('div',{staticClass:"d-flex mt-5"},[_c('vs-button',{attrs:{"icon":"","loading":_vm.isLoadingAllData},on:{"click":_vm.listAllItems}},[_vm._v(" ⏬ Descargar excel ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }