<template>
    <div class="my-3">
        <div class="w-100">
            <h1 class="h3 text-center">
                Ordenes encontradas
                <strong class="text-primary">
                    {{ itemsNumber }}
                </strong>
            </h1>

            <p>
                Por defecto el sistema consulta las ordenes en curso, es decir
                una orden que esta en un estado de entrega diferente al
                entregado o cancelado.
            </p>
        </div>

        <div class="my-3">
            <orders-filters
                v-model="query"
                :enabled-filters="$data.$enabledFilters"
                @enter="reloadItems"
            />
            <div class="d-flex justify-content-center mt-3">
                <vs-button v-show="query" relief @click="reloadItems">
                    Consultar 🔎
                </vs-button>
            </div>
        </div>

        <div
            class="d-flex justify-content-between flex-column flex-lg-row my-2"
        >
            <div class="d-flex justify-content-around">
                <tooltip-button
                    v-if="
                        $ability.hasScope('order:Consultancy') &&
                        selected.length === 1
                    "
                    icon="🗜"
                    message="Dividir y asignar proveedores"
                    @click="goToRefactor"
                />

                <tooltip-button
                    v-if="
                        $ability.hasScope('order:Complete') &&
                        selected.length === 1
                    "
                    icon="🏁"
                    message="Completar orden"
                    @click="completeOrderComponent"
                />

                <tooltip-button
                    v-show="selected.length"
                    icon="🧹"
                    message="Limpiar selección"
                    @click="clearSelected"
                />
            </div>

            <div class="d-flex align-items-end justify-content-around">
                <vs-pagination
                    v-model="currentPage"
                    :length="pagesNumber"
                    @input="listItems()"
                />
                <tooltip-redirect
                    v-if="$ability.hasScope('order:Create')"
                    to="/app/orders/new-in-merchant"
                    message="Registrar venta"
                >
                </tooltip-redirect>
            </div>
        </div>

        <div v-show="showTable" class="w-100 mt-2">
            <b-table
                ref="selectableTable"
                :fields="$data.$fields"
                :items="itemsCurrent"
                :dark="activeDark"
                :per-page="itemsCurrent.length"
                select-mode="multi"
                responsive
                outlined
                no-border-collapse
                hover
                selectable
                class="col-12"
                @row-selected="onRowSelected"
            >
                <template #cell(id)="data">
                    <div class="d-flex align-items-center">
                        <router-link
                            :to="`/app/orders/detail/${data.item.id}`"
                            class="text-center font-weight-normal btn btn-primary p-1"
                        >
                            <span> {{ data.item.id }}</span>
                        </router-link>
                    </div>
                </template>
                <template #cell(deliveryStatusMessage)="data">
                    <small
                        class="text-center font-weight-normal"
                        :class="
                            $data.$deliveryStatusesMessageClass[
                                data.item.deliveryStatus
                            ]
                        "
                    >
                        {{ data.item.deliveryStatusMessage }}
                    </small>
                </template>
                <template #cell(deliveryOptionMessage)="data">
                    <small
                        class="text-center"
                        :class="
                            data.item.deliveryOption === 'home'
                                ? 'text-info'
                                : ''
                        "
                    >
                        {{ data.item.deliveryOptionMessage }}
                    </small>
                </template>
                <template #cell(paymentStatusMessage)="data">
                    <small
                        class="text-center"
                        :class="
                            $data.$paymentStatusesMessageClass[
                                data.item.paymentStatus
                            ]
                        "
                    >
                        {{ data.item.paymentStatusMessage }}
                    </small>
                </template>
                <template #cell(paymentMethodMessage)="data">
                    <small class="text-center">
                        {{ data.item.paymentMethodMessage }}
                    </small>
                </template>
                <template #cell(customer)="data">
                    <small v-if="data.item.customer" class="text-center">
                        {{
                            `${data.item.customer.firstName} ${data.item.customer.lastName} / ${data.item.customer.phone}`
                        }}
                    </small>
                    <small v-else> Sin cliente asociado </small>
                </template>
                <template #cell(total)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.total | money }}
                    </small>
                </template>
                <template #cell(deliveryAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.deliveryAt | dateSimple }}
                    </small>
                </template>
                <template #cell(createdAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.createdAt | date }}
                    </small>
                </template>
                <template #cell(updatedAt)="data">
                    <small class="text-center font-weight-normal">
                        {{ data.item.updatedAt | date }}
                    </small>
                </template>
            </b-table>
            <div v-if="!itemsCurrent.length" class="col-11 mx-auto mt-4">
                <strong>
                    Nuestro sistema NO encuentra resultados. Intenta modificar
                    los filtros de búsqueda.
                </strong>
            </div>
            <div
                v-if="itemsCurrent.length && $ability.hasScope('order:ListAll')"
                class="d-flex mt-5"
            >
                <vs-button
                    icon
                    :loading="isLoadingAllData"
                    @click="listAllItems"
                >
                    ⏬ Descargar excel
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";

import { deliveryStatusesMessageClass } from "@/common/fields/delivery-status-codes";
import { paymentStatusesMessageClass } from "@/common/fields/payment-status-codes";
import { downloadExcel } from "@/common/lib/excel";

import OrdersFilters from "@/components/orders/OrdersFilters.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";
import TooltipRedirect from "@/components/utils/TooltipRedirect.vue";

export default {
    name: "IndexOrder",
    components: { OrdersFilters, TooltipRedirect, TooltipButton },
    layout: "app",
    meta: {
        roles: ["administrator", "support"]
    },
    data: () => ({
        loaderInstance: null,
        itemsCurrent: [],
        $deliveryStatusesMessageClass: deliveryStatusesMessageClass,
        $paymentStatusesMessageClass: paymentStatusesMessageClass,
        $enabledFilters: [
            "uid",
            "dateRange",
            "timeAgo",
            "weekAgo",
            "monthAgo",
            "origin",
            "deliveryStatus",
            "paymentStatus",
            "sellerId",
            "userId"
        ],
        $fields: [
            { key: "id", label: "ID", sortable: false },
            {
                key: "deliveryStatusMessage",
                label: "Estado",
                sortable: true
            },
            {
                key: "deliveryOptionMessage",
                label: "Opción de entrega",
                sortable: false
            },
            {
                key: "paymentStatusMessage",
                label: "Estado de pago",
                sortable: false
            },
            {
                key: "paymentMethodMessage",
                label: "Método de pago",
                sortable: false
            },
            {
                key: "customer",
                label: "Cliente",
                sortable: false
            },
            { key: "total", label: "Total", sortable: true },
            { key: "deliveryAt", label: "📆 Entrega", sortable: true },
            { key: "createdAt", label: "📆 Creación", sortable: true },
            { key: "updatedAt", label: "📆 Actualización", sortable: true }
        ],
        showTable: false,
        currentPage: 1,
        pagesNumber: 0,
        itemsNumber: 0,
        loadedAllData: false,
        isLoadingAllData: false,
        itemsAll: [],
        selected: [],
        query: {
            deliveryStatus: "",
            type: "web"
        }
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("order:List", "/app"))) return;
        this.listItems(true);
    },
    methods: {
        ...mapActions("orders", [
            "listOrdersPaginated",
            "listAllOrders",
            "completeOrder"
        ]),
        async listItems(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items, pagination } = await this.listOrdersPaginated({
                    complete,
                    page: this.currentPage,
                    query: this.query
                });
                this.itemsCurrent = items;
                if (pagination) {
                    this.itemsNumber = pagination.itemsNumber;
                    this.pagesNumber = pagination.pagesNumber;
                }
                this.showTable = true;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            this.currentPage = 1;
            await this.listItems(true);
        },
        async listAllItems() {
            this.isLoadingAllData = true;
            try {
                this.itemsAll = await this.listAllOrders();
                this.loadedAllData = true;
                const filename = `orders_page_${
                    this.currentPage
                }_${DateTime.now().toFormat("yyyy-MM-dd")}`;
                downloadExcel({ rows: this.itemsAll, filename });
            } catch (error) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingAllData = false;
            }
        },
        async completeOrderComponent() {
            const id = this.selected[0].id;
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                icon: "info",
                title: `Vas a completar la orden ${id}`,
                text: `Al completar la orden vas a.
Actualizar el estado de entrega a completado.
Actualizar el estado de pago a completado.
Actualizar el valor pagado al valor total de la orden.
Decrementar la cantidad de productos de la orden en el inventario del comercio asignado. En caso de no haber sido decrementado anteriormente.`,
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (!dialogResult.isConfirmed) return;

            this.loaderInstance = this.$vs.loading({ type: "square" });

            try {
                await this.completeOrder({
                    id
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        onRowSelected(items) {
            this.selected = items;
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected();
            this.selected = [];
        },
        goToRefactor() {
            const id = this.selected[0].id;
            window.open(`/app/orders/refactor/${id}`, "_blank").focus();
        }
    }
};
</script>
